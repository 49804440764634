@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

/* ----------------------------------------------------------------------*/
/* SECTION AND ROWS */
/* ----------------------------------------------------------------------*/
section {
    display: block;
    position: relative;
    padding: 3.5em 0 3.5em 0;

}

.row-width {

    margin: 0 auto;
    max-width: 1240px;
    padding: 0 1.5em 0 1.5em;

    @include respond-max(xs) {

        padding: 0 0.6em 0 0.6em;
    }

    @include respond-max(sm) {

        padding: 0 0.8em 0 0.8em;
    }

}

/* ----------------------------------------------------------------------*/
/* TEXT */
/* ----------------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
span,
input,
textarea {
    font-family: 'Lato', sans-serif;

    line-height: 1.3;
    letter-spacing: 1px;
    font-style: normal;
    word-wrap: break-word;


}

h1 {
    font-size: 3.5em;
    font-weight: 400;
    font-style: normal;
    text-transform: normal;
    letter-spacing: 2px;
}

h2 {
    font-size: 1.9em;
    text-align: center;
    text-transform: normal;
    color: $primary-blue;

    font-weight: 500;

    @include respond-max(md) {
        font-size: 1.8em;

    }


    @include respond-max(sm) {
        font-size: 1.7em;

    }

    @include respond-max(xs) {
        font-size: 1.7em;
    }

}

h3 {
    color: $primary-blue;
    text-transform: normal;
    letter-spacing: 1px;
    font-size: 1.3em;
    line-height: 1.3;
    font-weight: 500;

    @include respond-max(md) {
        font-size: 1.2em;

    }

    @include respond-max(sm) {
        font-size: 1.2em;


    }

    @include respond-max(xs) {
        font-size: 1.2em;

    }

}

h4 {
    line-height: 1.4;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 1px;
    color: $grey;

}

p {

    font-family: myriad-pro, sans-serif;
    font-style: normal;
    line-height: 1.3;
    // letter-spacing: 1px;
    font-size: 1.2em;
    font-weight: 500;
    color: $grey;
    text-align: left;

    // polskie znaki
    -moz-font-feature-settings: "smcp";
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";

    @include respond-max(sm) {
        font-size: 1.1em;
    }
}

ol {
    font-size: 1em;

    font-family: myriad-pro, sans-serif;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1px;
    color: $grey;
    -moz-font-feature-settings: "smcp";
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";
}

a,
a:hover,
a:active,
a:visited,
a:active {
    color: inherit;
    cursor: pointer;
    text-decoration: none;

}

a[href^="tel"]:link,
a[href^="mailto:"]:link a[href^="tel"]:visited,
a[href^="mailto:"]:visited {
    color: inherit;
    cursor: pointer;
    text-decoration: none;

}

strong {
    font-weight: 800;
}

.text-a-c {
    text-align: center;
}

.red {
    color: $primary-red;
}


.loader {
    position: relative;
    margin: auto;
    border: 5px solid #EAF0F6;
    border-radius: 50%;
    border-top: 5px solid #243f91;
    width: 50px;
    height: 50px;
    animation: spinner 1s linear infinite;
    margin-top: 3em;
    margin-bottom: 3em
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.display-none {
    display: none;
}
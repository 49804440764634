@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.player-section-p {
    h2 {
        margin-bottom: 48px;
    }

    .player-p-container {
        padding: 1em;

        .player-item {
            display: flex;
            flex-direction: row;

            img {
                object-fit: cover;
                width: 180px;
                height: 200px;
            }

            .player-info {
                align-self: center;
                margin-left: 2em;
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 1.1em;
                    margin: 0.2em 0 0.2em 0;

                    span {
                        font-size: 1.1em;
                    }
                }
            }
        }

        p {
            margin-top: 2em;
        }
    }

    .btn-player-singel {

        margin: 0 auto;
        margin-top: 2em;
    }
}
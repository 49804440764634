@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.player-section-p {
    h2 {
        margin-bottom: 1em;
    }

    .player-p-container {

        .player-item {
            padding: 0.5em;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 16px;
            background-color: #fff;
            box-shadow: 2px 2px 10px 0 #c4c4c4;
            height: 100%;



            .player-info-data-coverImg {
                margin: 1em;
                border-radius: 100%;
                object-fit: cover;
                object-position: center center;
                width: 180px;
                height: 180px;
            }

            .player-item-info {
                align-self: center;
                margin-left: 0.5em;
                display: flex;
                flex-direction: column;

                .player-info-data {

                    display: flex;
                    align-items: center;
                    margin: 0.6em 0;

                    img {
                        width: 38px;
                        height: 38px;
                        margin-right: 1em;
                    }

                    h3 {
                        text-transform: uppercase;
                        font-size: 0.9em;
                        font-weight: 700;
                        margin: 0.2em 0 0.2em 0;

                        span {
                            text-transform: none;
                            font-weight: 500;

                            color: $grey;
                            font-size: 1.1em;
                        }

                    }
                }
            }

            .player-item-line {
                position: relative;
                content: '';
                height: 100px;
                background-color: $grey;
                margin: 0 1.5em 0 3em;

                &::after {
                    position: absolute;
                    content: '';
                    top: -40%;
                    width: 1px;
                    height: 180%;
                    opacity: 0.5;
                    background-color: $grey;
                }
            }

            .player-item-sponsor {
                justify-content: center;
                flex-grow: 1;
                text-align: center;

                margin-right: 1em;

                p {
                    font-size: 1em;
                    text-align: center;
                }

                img {

                    margin: 1em;
                    object-fit: fill;
                    width: 130px;
                    height: 130px;
                }
            }

            @include respond-max (md) {
                // padding-top: 1.5em;
                padding: 1em;


                flex-direction: column;

                .player-item-info {
                    margin-top: 2em;
                    margin-left: 0;

                }

                .player-item-line {
                    display: none;
                }

                .player-item-sponsor {
                    margin-right: 0;
                }

            }
        }

        p {
            color: $grey;
            margin-top: 2em;
        }


    }


}
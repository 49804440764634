@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.footer-bottom {


    footer {


        padding-top: 3em;
        padding-bottom: 3em;
        background-color: $primary-blue;


        .footer-container {


            .footer-item {
                color: #fff;

                h4 {
                    color: #fff;
                    font-weight: 500;

                }

                p {
                    line-height: 1.4;
                    color: #fff;
                }
            }
        }


    }

    .copy-right {
        padding-top: 0.3em;
        padding-bottom: 0.5em;
        background-color: $primary-blue;

        .copy-right-line {
            padding-bottom: 0.5em;

            display: block;
            margin: 0 auto;
            max-width: 1320px;
            height: 1px;
            content: '';
            border-top: solid 1px rgba(255, 255, 255, 0.5);

        }

        .copy-right-container {

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 0.8em;
            font-weight: 600;
            color: #fff;
            background-color: $primary-blue;


            .copy-right-item {


                a:last-child {
                    justify-self: right;
                    ;
                }

                @media all and (max-width: 520px) {
                    .copy-right-text {
                        max-width: 18ch;
                    }
                }

                @include respond-max(xsm) {
                    .copy-right-text {
                        max-width: 100%;
                    }
                }

            }

            @include respond-max(xsm) {
                flex-direction: column;

                line-height: 1.4;




            }

        }
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";




.coach-p-container {
    margin-bottom: 2em;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 10px 0 #c4c4c4;
    padding: 1.5em;

    &:last-child {
        margin-bottom: 0;
    }

    .coach-item {
        display: flex;
        flex-direction: column;
        align-items: center;


        .coach-name {
            font-size: 1.4em;
            font-weight: 700;
            margin: 1em 0;
            text-align: center;
        }

        .coach-info-data-coverImg {
            border-radius: 100%;
            object-fit: cover;
            object-position: center top;
            width: 180px;
            height: 180px;
            margin-bottom: 1em;

            @include respond-max(xs) {
                width: 150px;
                height: 150px;
            }
        }

        .coach-item-info {

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;


            h3 {
                font-size: 1.2em;
                font-weight: 700;
                margin: 0.5em 0;
                text-align: center;


            }

            .coach-info-data {
                display: flex;
                align-items: center;
                margin: 0 0 0.3em 0;


                img {
                    width: 17px;
                    height: 17px;
                    margin-right: 10px;
                }

                h3 {
                    font-size: 1.1em;
                    font-weight: 800;
                    margin: 0;


                }

                span {
                    color: $primary-blue;
                    font-size: 1.1em;
                    text-transform: none;
                    font-weight: 500;
                    word-break: break-all;



                }
            }
        }



        .coach-item-text {
            justify-content: center;
            flex-grow: 1;
            align-content: center;


            p {
                font-size: 1em;
            }
        }
    }

    .coach-person-text {
        margin-top: 2em;
    }
}
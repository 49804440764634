@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.news-card {
    position: relative;
    // max-width: 300px; 
    margin: 0.7em;
    min-height: 500px;

    background-color: #fff;
    box-shadow: 2px 2px 10px 0 #c4c4c4;
    cursor: pointer;
    transition: all 0.5s;
    padding: 1px;
    border-radius: 7px;


    @include respond-min(sm) {
        width: 300px;
    }

    @include respond-max(sm) {
        max-width: 375px;
    }




    &:hover {
        h3 {
            color: $primary-red;
        }

        .img-box {

            img {
                transition: all 0.5s;
                transform: scale(1.2);

            }
        }
    }


    .img-box {
        position: relative;
        width: calc(100% - 2em);
        height: 180px;
        overflow: hidden;
        margin: 1em 1em 0 1em;


        img {
            transition: all 0.5s;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: cover;
            object-position: center center;

        }
    }

    .news-card-text {
        padding: 1em;
        display: flex;
        flex-direction: column;
        height: max-content;
        position: relative;

        p {
            margin-top: 1em;
            text-align: left;
        }





    }

    .news-card-data {
        position: absolute;

        bottom: 0.7em;
        left: 1.2em;
        align-self: end;
        width: fit-content;
        color: $grey;
        font-size: 0.8em;



        @include respond-max(xs) {
            position: relative;
        }
    }

    .news-card-onhover {
        width: 100%;
        height: 3em;
        background-color: $primary-red;

        display: flex;
        justify-content: center;
        align-content: center;

        transition: all 0.5s;

        .news-btn {
            visibility: visible;
            transition: 0.1s;
            line-height: 0;
        }

    }

    &:hover {
        .news-card-onhover {
            width: 100%;

            .news-btn {
                transition: 0.1s;

                visibility: visible;
            }
        }

    }
}
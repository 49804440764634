@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.pagination {

    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: none;
    list-style: none;
    user-select: none;
    width: 100%;

    .pagination-prev {


        cursor: pointer;
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        // border: solid 2px $primary-blue;
        // border-radius: 100%;
        z-index: 1;
        transition: all .2s linear;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 35%;
            height: 10%;
            top: 41%;
            left: 55%;
            background: #999;
            z-index: 2;
            transform: translate(-50%, -50%) rotate(-45deg);
            transition: all .2s linear;
        }

        &:after {
            z-index: 3;
            top: 59%;
            left: 55%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:hover {
            // border: solid 8px #777;


            &:after,
            &:before {
                background: $primary-blue;
            }
        }

        &:active {
            // border: solid 8px #111;

            &:after,
            &:before {
                background: #111;
            }
        }

    }

    .pagination-next {
        cursor: pointer;
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        // border: solid 2px $primary-blue;
        // border-radius: 100%;
        z-index: 1;
        transition: all .2s linear;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 35%;
            height: 10%;
            top: 41%;
            left: 55%;
            background: rgb(153, 153, 153);
            z-index: 2;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all .2s linear;
        }

        &:after {
            z-index: 3;
            top: 59%;
            left: 55%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
            // border: solid 8px #777;


            &:after,
            &:before {
                background: $primary-blue;
            }
        }

        &:active {
            // border: solid 8px #111;

            &:after,
            &:before {
                background: #111;
            }
        }
    }

    .page-numbers {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        user-select: none;
        padding: 0.5em;


        .page-item {
            margin: 0.5em;

            color: $primary-blue;
            border: solid 1px $primary-blue;
            padding: 0.2em 0.5em;
            transition: all .2s ease-in;


            &:hover {
                color: $primary-red;
                transition: all .2s ease-in;

            }

            .page-link {

                cursor: pointer;
                font-size: 1.1em;
                font-weight: 400;


            }



        }



        .page-item-active {
            color: $primary-red;
            border: solid 2px $primary-blue;

        }
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";



.site-navbar,
.site-navbar-condensed {

    padding-top: 2em;
    width: 100vw;
    height: 100px;
    position: fixed;
    z-index: 9999;
    color: #fff;
    background-color: transparent;
    transition: top 0.3s ease-in-out;
    user-select: none;

    .links {
        display: flex;
        justify-content: space-between;

        .menu-left {
            justify-content: flex-end;
        }

        .logo {

            flex: 0 1 40vw;
            align-self: center;
            text-align: center;
            width: 200px;
            height: 200px;

            .img {
                width: 100%;
                height: 100%;


            }
        }

        ul {

            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex: 0 1 50vw;

            .li-main {
                position: relative;
                padding: 0.3em 1.5em;
                align-self: top;
                height: 100%;

                &::after {
                    transition: 0.5s;
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 2em;
                    transform: translate(-50%, 0);
                    height: 3px;
                    width: 0;
                    background-color: #fff;
                    z-index: 10;
                }

                &:hover {
                    &::after {
                        transition: 0.5s;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 2em;
                        transform: translate(-50%, 0);
                        height: 3px;
                        width: 100%;
                        background-color: #fff;
                        z-index: 10;
                    }
                }




                @include respond-max(lg) {
                    margin: 0 1.2em;


                }

                .link-active {
                    position: relative;

                    &::after {
                        transition: 0.5s;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 1.7em;
                        transform: translate(-50%, 0);
                        height: 3px;
                        width: 100%;
                        background-color: #fff;
                        z-index: 10;
                    }
                }

                a {
                    text-transform: uppercase;
                    font-size: 0.9em;
                    line-height: 25px;
                    font-weight: 800;
                    letter-spacing: 1px;

                    &:hover {
                        color: $primary-red;
                        transition: 0.5s;

                    }
                }

                &:hover .ul-sub-menu {
                    visibility: visible;
                    transition: 0.4s;
                    opacity: 1;

                    li {
                        transition: 0.4s;

                        visibility: visible;
                    }

                }

                .ul-sub-menu {
                    position: absolute;
                    left: 50%;
                    top: 3.3em;
                    transform: translateX(-50%);

                    width: max-content;
                    list-style: none;
                    margin: 0 0 0 0;


                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    background-color: $primary-blue;

                    visibility: hidden;
                    opacity: 0;

                    li {
                        visibility: hidden;

                        margin: 0;
                        padding: 0.5em 1.5em;

                        &:hover {
                            background-color: #122f6e;
                            color: #fff;
                        }

                        a {
                            font-size: 0.7em;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .social-media {
                width: fit-content;
                flex-direction: row;
                display: flex;

                cursor: pointer;

                li {
                    display: flex;
                    margin: 0 0.8em;

                    .social-img {

                        justify-self: center;

                        width: 25px;
                        height: 25px;
                        transition: transform 0.5s;


                        &:hover {
                            transform: translateY(-8px);
                        }
                    }
                }
            }
        }
    }


    .links-mobile {

        .menu-left {
            justify-content: none;
        }

        .logo {
            align-self: center;
            text-align: center;


            .img {

                width: 200px;
                height: 200px;

                @include respond-max(md) {
                    width: 160px;
                    height: 160px;
                }


                @include respond-max(sm) {
                    width: 120px;
                    height: 120px;
                }

                @include respond-max(xs) {
                    width: 80px;
                    height: 80px;

                }


            }
        }

        ul {

            display: none;

        }

    }
}

.site-navbar-condensed {
    padding-top: 0;
    height: 100px;
    color: $primary-blue;
    background-color: transparent;
    border-bottom: 1px solid rgba(175, 175, 175, 0.5);

    .links {

        .menu-left {
            justify-content: flex-end;
            color: $primary-blue;
        }

        .logo {
            flex: 0 1 40vw;
            padding: 15px;
            width: 100%;
            height: 100%;

            .img {

                width: 70px;

                height: 70px;



            }

        }

        ul {


            .li-main {

                height: 100%;
                display: flex;
                align-items: center;


                &::after {
                    transition: 0.5s;
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 4em;
                    transform: translate(-50%, 0);
                    height: 3px;
                    width: 0;
                    background-color: $primary-blue;
                    z-index: 10;
                }

                &:hover {
                    &::after {
                        transition: 0.5s;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 4em;
                        transform: translate(-50%, 0);
                        height: 3px;
                        width: 85%;
                        background-color: $primary-blue;
                        z-index: 10;
                    }
                }

                a {

                    line-height: 25px;
                    font-weight: 800;

                }

                .ul-sub-menu {
                    position: absolute;
                    margin: 0;
                    top: 100px;

                    li {
                        color: #fff;
                        transition: 0.5s;

                    }
                }

            }

            .social-media {
                width: fit-content;
                flex-direction: row;
                display: flex;

                cursor: pointer;

                li {
                    display: flex;
                    margin: 0 0.8em;
                    align-items: center;

                    .social-img {

                        justify-self: center;
                        align-self: top;
                        width: 25px;
                        height: 25px;
                        transition: transform 0.5s;



                        &:hover {
                            transform: translateY(-8px);
                        }
                    }
                }
            }
        }
    }

    .links-mobile {

        .menu-left {
            justify-content: none;
        }

        .logo {
            flex: 0 1 40vw;
            padding: 15px;

            .img {
                width: 70px;
                height: 70px;
            }
        }

        ul {
            display: none;

        }

    }


    .mobile-menu {


        span {
            background-color: $primary-red;
        }
    }

    .isopen {
        span {
            background-color: $primary-red;
        }
    }
}

.site-navbar-mobile {
    position: fixed;
    transition: top 0.3s ease-in-out;
    margin-top: 0;
    height: 100vh;
    color: $primary-blue;
    background-color: #fff;
    overflow-y: scroll;

    @include respond-max(sm) {
        height: 100%;
        padding-bottom: 70px;
    }

    .mobile-down {
        top: 0;
        display: block;
        position: relative;
        width: 100vw;
        height: 120px;
    }

    .logo {
        position: absolute;
        top: 1em;
        left: 1em;
        padding: 0px;
        flex: none;
        align-self: center;
        text-align: center;

        .img {

            margin: 1.5em;
            width: 80px;
            height: 80px;


        }
    }

    .links-mobile {
        position: relative;
        align-self: center;
        // top: calc(50% -120px);
        left: 50%;

        transform: translate(-50%, 0);
        display: flex;
        justify-content: center;
        flex-direction: column;

        width: 100vw;
        height: min-content;

        margin: 0em;
        overflow-y: scroll;

        padding-bottom: 2em;

        .menu-left {
            justify-content: flex-start;
        }



        ul {
            flex: none;
            height: min-content;
            list-style: none;
            padding: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            .li-main {
                position: relative;
                padding: 1.1em 0em 0 0;

                align-self: top;
                text-align: center;
                width: auto;

                &::after {

                    transition: 0.5s;
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 3em;
                    transform: translate(-50%, 0);
                    height: 3px;
                    width: 0;
                    background-color: $primary-blue;
                    z-index: 10;
                }

                &:hover {
                    &::after {
                        transition: 0.5s;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 3em;
                        transform: translate(-50%, 0);
                        height: 3px;
                        width: 100%;
                        background-color: $primary-blue;
                        z-index: 10;
                    }
                }




                @include respond-max(lg) {
                    margin: 0 1.2em;


                }

                .link-active {
                    position: relative;

                    &::after {
                        transition: 0.5s;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 1.7em;
                        transform: translate(-50%, 0);
                        height: 3px;
                        width: 100%;
                        background-color: #fff;
                        z-index: 10;
                    }
                }

                a {
                    text-transform: uppercase;
                    font-size: 1.1em;
                    line-height: 25px;
                    font-weight: 800;
                    letter-spacing: 1px;

                    &:hover {
                        color: $primary-red;
                        transition: 0.5s;

                    }
                }



                .ul-sub-menu {
                    position: relative;
                    left: 50%;
                    top: 0.6em;
                    transform: translateX(-50%);

                    width: max-content;
                    list-style: none;
                    margin: 0 0;


                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    background-color: #fff;

                    display: none;
                    opacity: 0;

                    li {
                        visibility: visible;

                        margin: 0;
                        padding: 0.5em 1.5em;

                        // &:hover {
                        //     background-color: $primary-blue;
                        //     color: #fff;

                        //     a {
                        //         color: #fff;
                        //     }
                        // }

                        a {
                            font-size: 0.9em;
                            color: $primary-red;

                            &:hover {
                                color: $primary-blue;
                            }
                        }
                    }
                }
            }

            .sub-menu-show {


                .ul-sub-menu {

                    display: flex;
                    transition: 0.4s;
                    opacity: 1;

                    li {

                        transition: 0.4s;
                        visibility: visible;
                    }
                }

            }


            .social-media {
                width: fit-content;
                flex-direction: row;
                display: flex;
                margin-top: 3em;
                cursor: pointer;



                li {

                    display: flex;
                    margin: 0 0.8em;

                    .social-img {

                        justify-self: center;

                        width: 25px;
                        height: 25px;
                        transition: transform 0.5s;


                        &:hover {
                            transform: translateY(-8px);
                        }
                    }

                }
            }
        }
    }

}

.site-navbar-opacity {
    background-color: #fff;
    transition: color 0.3s;

}





//mobile 
.mobile-menu {
    display: block;
    position: absolute;
    top: 50px;
    transform: translateY(-50%);
    right: 2em;
    z-index: 100000;
    cursor: pointer;

    @include respond-max (md) {
        right: 1.5em;
    }

    span {
        position: relative;
        display: block;
        width: 33px;
        height: 2px;
        margin-bottom: 6px;
        background-color: #fff;

        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }
}

.isopen {

    span {
        background-color: $primary-red;
    }

    span:nth-last-child(1) {
        transform: rotate(-45deg) translate(0px, 0px);
    }

    span:nth-last-child(3) {
        opacity: 1;
        transform: rotate(45deg) translate(0px, -5px);
    }


    span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

}




.noactive {
    display: none;
}

.navup {
    transition: top 0.3s ease-in-out;
    top: -100px;
}

.navdown {
    transition: top 0.3s ease-in-out;
}

.position-fixed {
    position: fixed;
}
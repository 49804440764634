 @use "sass:map";

 $grid-breakpoints: ('xs': 320px,
     'xsm':375px,
     'sm': 440px,
     'sl': 590px,
     'md': 767px,
     'lg': 1100px,
     'xl': 1215px,
     'xxl': 1800px);

 // respond is the name of your mixin

 @mixin respond-min ($breakpoint) {

     // $breakpoint is simply a variable that can have several values

     @if $breakpoint==xs {

         // here `laptop` is the value of $breakpoint
         // when call laptop, we mean the following piece of code        

         @media only screen and (min-width: map-get($grid-breakpoints, 'xs')) {
             @content;
         }
     }

     @if $breakpoint==xsm {
         @media only screen and (min-width: map-get($grid-breakpoints, 'xsm')) {
             @content;
         }
     }

     @if $breakpoint==sm {
         @media only screen and (min-width: map-get($grid-breakpoints, 'sm')) {
             @content;
         }
     }

     @if $breakpoint==sl {
         @media only screen and (min-width: map-get($grid-breakpoints, 'sl')) {
             @content;
         }
     }

     @if $breakpoint==md {
         @media only screen and (min-width: map-get($grid-breakpoints, 'md')) {
             @content;
         }
     }

     @if $breakpoint==lg {
         @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
             @content;
         }
     }

     @if $breakpoint==xl {
         @media only screen and (min-width: map-get($grid-breakpoints, 'xl')) {
             @content;
         }
     }

     @if $breakpoint==xxl {
         @media only screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
             @content;
         }
     }
 }



 @mixin respond-max ($breakpoint) {

     // $breakpoint is simply a variable that can have several values

     @if $breakpoint==xs {

         // here `laptop` is the value of $breakpoint
         // when call laptop, we mean the following piece of code        

         @media only screen and (max-width: map-get($grid-breakpoints, 'xs')) {
             @content;
         }
     }

     @if $breakpoint==xsm {
         @media only screen and (max-width: map-get($grid-breakpoints, 'xsm')) {
             @content;
         }
     }

     @if $breakpoint==sm {
         @media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
             @content;
         }
     }

     @if $breakpoint==sl {
         @media only screen and (max-width: map-get($grid-breakpoints, 'sl')) {
             @content;
         }
     }

     @if $breakpoint==md {
         @media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
             @content;
         }
     }

     @if $breakpoint==lg {
         @media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
             @content;
         }
     }

     @if $breakpoint==xl {
         @media only screen and (max-width: map-get($grid-breakpoints, 'xl')) {
             @content;
         }
     }

     @if $breakpoint==xxl {
         @media only screen and (min-width: map-get($grid-breakpoints, 'xxl')) {
             @content;
         }
     }
 }


 // fonts


 @mixin p-font {
     font-size: 0.8em;
     font-weight: 300;
     color: $dark-grey;
     letter-spacing: 0.5px;
     text-align: left;

 }
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.history-section-p {
    background-color: #fff;

    padding-top: 0;

    h2 {
        margin-top: 48px;
        margin-bottom: 48px;
    }


    p {
        margin-bottom: 1em;
        text-align: justify;
        // text-indent: 30px;
    }

    ul {

        margin-left: 0;
        list-style: none;
        margin-bottom: 0.7em;

        ul li {
            margin-left: 1em;
            margin-top: 0.2em;
            margin-bottom: 0.2em;
        }
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";



.sponsor-item {
    background-color: transparent;
    margin: 1em;
    //     width: 100%;
    //    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        z-index: 11;
        position: relative;
        width: 200px;

        @include respond-max(xs) {
            max-width: 170px;
        }
    }




    h4 {
        margin-top: 0.3em;
        text-align: center;
        font-size: 0.8em;
        color: #fff;
        font-weight: 600;
    }

    .img-inst {
        width: 150px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 150px;
            height: auto;
        }
    }


    .img-spon {
        // width: 200px;
        height: 100%;
        display: flex;
        padding: 5px;
        // flex-direction: row;
        justify-content: center;
        align-items: center;
        border: red 1px solid;
        // margin: 0 1em;




        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border: red 1px solid;


        }

        @include respond-max(md) {

            height: 90px;

        }

        @include respond-max(sm) {

            height: 80px;

        }



        // img {
        //     width: 100%;
        //     height: 100%;


        // }
    }



}
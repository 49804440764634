@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.sponsors-slider-container {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-content: center;
    border: green solid 1px;

    div {
        border: solid orange 1px;
    }
}
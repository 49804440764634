@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.section-section-p {


    .section-p-container {
        margin-bottom: 2em;

        &:last-child {
            margin-bottom: 0;
        }

        .section-item {

            padding: 1em;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 16px;
            background-color: #fff;
            box-shadow: 2px 2px 10px 0 #c4c4c4;
            height: 100%;


            .section-info-data-coverImg {
                max-width: 300px;
                height: 250px;
                flex: 1;

                img {
                    padding: 1em;

                    object-fit: cover;
                    object-position: center center;
                    max-width: 100%;
                    height: 100%;



                }


            }

            .section-item-info {

                width: 40%;
                align-self: center;
                margin-left: 0.5em;
                display: flex;
                flex-direction: column;

                .section-info-data {

                    display: flex;
                    align-items: center;
                    margin: 0.3em 0;
                    justify-content: center;

                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 10px;
                    }

                    h3 {
                        font-size: 1.1em;
                        font-weight: 600;
                        margin-bottom: 1em;


                    }

                    span {
                        color: $primary-blue;
                        font-size: 1em;
                        text-transform: none;
                        font-size: 1em;
                    }

                    .section-info-adress {
                        margin-top: 1em;
                        max-width: 20ch;

                    }

                }
            }

            .section-item-line {
                position: relative;
                content: '';
                height: 120px;
                background-color: $grey;
                margin: 0 1.5em 0 3em;

                &::after {
                    position: absolute;
                    content: '';
                    top: -40%;
                    width: 1px;
                    height: 180%;
                    opacity: 0.5;
                    background-color: $grey;
                }
            }



            @include respond-max (lg) {
                padding-top: 1.5em;


                .section-item-info {
                    margin-top: 2em;
                    margin-left: 0;
                    width: 100%;

                }

                .section-item-line {
                    display: none;
                }

            }

            .section-info-coaches {
                margin-right: 1em;

                p {
                    text-align: center;
                    margin-bottom: 1em;
                }

                .section-info-coaches-data {
                    width: max-content;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    margin: 1em;

                    .section-info-coaches-data-img {
                        text-align: center;
                        margin-right: 1em;
                        width: 72px;
                        height: 72px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                            object-fit: cover;
                            object-position: center top;

                        }
                    }

                    span {
                        color: $primary-blue;
                        font-size: 1em;
                        text-transform: none;
                        font-size: 1em;
                    }
                }
            }

            @include respond-max (lg) {
                flex-direction: column;

                .section-info-data-coverImg {
                    padding: 0;
                }

                .section-item-info {
                    width: auto;
                    margin-left: 0em;

                }

                .section-info-coaches {
                    margin-top: 2em;
                }

            }

        }
    }
}
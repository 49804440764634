@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.godown {
    cursor: pointer;
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    color: #fff;
    transition: all 0.5s;
    z-index: 100;

    @include respond-max(md) {
        bottom: 8%;

    }

    &:hover {
        transform: translate(-50%, 10px);
    }

    span {
        display: block;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1em;
        letter-spacing: 4px;
        transition: all 0.5s;
        opacity: 0.8;

        @include respond-max(xs) {
            font-size: 0.7em;
        }

        @include respond-max(sm) {
            font-size: 0.8em;
        }

        @include respond-min(md) {
            font-size: 1em;

        }

        @include respond-min(xl) {
            font-size: 1.1em;
        }

    }


    img {
        width: 25px;
        height: 25px;
        margin-bottom: 1em
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.players-p-container {
    margin-top: 3em;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .players-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        transition: all 0.3s;

        img {
            object-fit: cover;
            width: 75px;
            border-radius: 50%;
            height: 75px;
            margin: 1em;
            box-shadow: 2px 2px 4px 0 #c4c4c4;
            transition: all 0.3s;


        }


        &:hover {
            img {
                transform: scale(1.1);
            }

            h3 {
                color: $primary-red;
            }
        }
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.news-section-p {
    background-color: #fff;

    flex: 1;

    .news-section-search {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .news-container {
        margin-top: 2em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .news-search-noresult {
            align-content: center;
            justify-self: center;
            flex: 1;
            text-align: center;


        }
    }

    .news-btn {
        margin: 0 auto;
        margin-top: 2em;
    }
}
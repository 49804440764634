@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

//reset

//===========================================
//-------------------------------------------
[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    border: none;
    outline: none;
    background: none;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button;
    /* for input */
    -webkit-user-select: none;
    /* for button */
    -moz-user-select: none;
    -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
    border: none;
    outline: none;
}

/* Make `a` like a button */
[role="button"] {
    color: inherit;
    cursor: default;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border: none;
    outline: none;
}

//-------------------------------------------
//===========================================

.btn-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    transition: all 0.5s;
    border: none;
    outline: none;
    width: fit-content;
    z-index: 100;



    &:hover {
        transform: translateX(10px);
    }

    &:active,
    :focus {

        letter-spacing: 1px;
        transition: all 0.5s;
    }

    .arrow {


        &.-right {
            width: 2.8em;
            height: 1px;
            margin-left: 1em;
            position: relative;
            background-color: #fff;
            transition: all 0.2s ease-in-out;
            transition-delay: 0;
            will-change: transform;

            .shaft {
                right: -2px;
                top: 2px;
                height: 3px;
                display: block;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transition: all 0.2s;
                    transition-delay: 0;
                    width: 9px;
                    height: 1px;
                    background-color: $white;
                    position: absolute;
                    content: '';
                    transition-delay: 0.3s;
                    transition: all 0.5s;
                }

                &:before {
                    top: -4px;
                    transform: rotate(30deg);
                }

                &:after {
                    top: 0px;
                    transform: rotate(-30deg);
                }
            }
        }
    }


}

.btn-solid {

    @include respond-max(sm) {
        font-weight: 400;

        font-size: 0.8em;

    }
}

.btn-light {
    font-size: 1.2em;
    font-weight: 300;
    letter-spacing: 4px;


    @include respond-max(sm) {
        font-weight: 400;

        font-size: 0.8em;

    }

    &:active,
    :focus {
        font-weight: 300;
        letter-spacing: 2px;
        transition: all 0.5s;
    }

    .arrow {


        &.-right {

            width: 2.3em;
            height: 1px;
            margin-left: 0.2em;
            position: relative;
            background-color: $grey;
            transition: all 0.2s ease-in-out;
            transition-delay: 0;
            will-change: transform;

            .shaft {
                right: -0.06em;
                top: -0.15em;

                display: block;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transition: all 0.2s;
                    transition-delay: 0;
                    width: 0.7em;
                    height: 1px;
                    background-color: $grey;
                    position: absolute;
                    content: '';
                    transition-delay: 0.3s;
                    transition: all 0.5s;
                }

                &:before {
                    top: -0.02em;
                    transform: rotate(30deg);
                }

                &:after {
                    top: 0.32em;
                    transform: rotate(-30deg);
                }
            }
        }
    }
}



.btn-white {
    color: $white;

    .arrow {
        &.-right {
            background-color: $white;

            .shaft {

                &:before,
                &:after {
                    background-color: $white;
                }
            }
        }
    }
}

.btn-arrow-blue {
    color: $primary-blue;
    border: $primary-blue;

    .arrow {
        &.-right {
            background-color: $primary-blue;

            .shaft {

                &:before,
                &:after {
                    background-color: $primary-blue;
                }
            }
        }
    }
}

.arrow-element {
    img {
        width: 80px;
        height: 80px;
    }

    h2 {
        color: #fff
    }
}



//-------------------------------------------
.btn-border {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    transition: all 0.5s;
    border: none;
    outline: none;
    width: fit-content;
    z-index: 100;
    text-align: center;
    border: solid 2px;
    padding: 0.5em 2em;


    @include respond-max(sm) {

        font-size: 1em;

    }

}

.btn-white {
    color: #fff;
    border-color: #fff;

    &:hover {
        background-color: #fff;
        color: $primary-blue;
        border-color: #fff;

    }
}

.btn-red {
    color: $primary-red;
    border-color: $primary-red;

    &:hover {
        color: #fff;
        background-color: $primary-blue;
        border-color: $primary-blue;

    }
}

.btn-blue {
    color: $primary-blue;
    border-color: $primary-blue;

    &:hover {
        color: #fff;
        background-color: $primary-blue;
        border-color: $primary-blue;

    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.supportus-section-p {
    background-color: #fff;
    padding: 3em 0 3em 0;
    flex: 1;


    .supportus-box {
        width: 70%;
        background-color: $primary-red;
        padding: 3em 1em 3em 1em;
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        margin-top: 3em;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 2px 2px 10px 0 #c4c4c4;




        @include respond-max(md) {
            padding-bottom: 0;
        }



        .supportus-box-icon {
            width: 120px;
            height: auto;
            height: auto;
            margin-bottom: 2em;


            @include respond-max(sm) {
                width: 100px;

                font-size: 1.2em;
            }
        }


        .supportus-box-text {

            p {
                z-index: 110;
                position: relative;

                font-size: 1.5em;
                font-weight: 500;
                color: #fff;
                text-align: center;

                @include respond-max(sm) {

                    font-size: 1.2em;
                }
            }
        }



        .supportus-bar-img {
            width: 30vw;
            max-width: 337px;
            z-index: 100;
            position: absolute;
            right: 3em;
            bottom: 0em;

            @include respond-max(md) {
                width: auto;
                max-width: 50vw;
                position: relative;
                left: 50%;
                transform: translateX(-86%);
            }

        }
    }
}
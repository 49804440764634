@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.coach-section-p {

    .coach-container {
        margin-top: 3em;
    }

    .coach-img {
        height: 400px;
        width: 100%;
        object-fit: cover;
        margin: 3em 0;
    }
}
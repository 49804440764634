@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.site-in-work {

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.bottom-shape {
    position: absolute;
    bottom: -3em;
    content: '';
    background-color: #fff;
    width: 100%;
    height: 9em;
    clip-path: ellipse(70% 100% at 50% 0%);
    z-index: 0;
}

.maskotka {
    position: absolute;

    width: 25vw;
    max-width: 450px;

    right: 3%;

    bottom: 0px;
    z-index: 10;


    img {
        display: block;
        width: 100%;
    }

    @include respond-max(xl) {

        width: 15vw;
    }

    @include respond-max(md) {

        display: none;
    }
}

h2 {
    font-size: 4em;
}

.aboutus-section {
    background-color: #fff;


    .abouts-container {
        max-width: 700px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .abouts-item {
            cursor: pointer;
            margin: 1em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: transform 0.5s;

            &:hover {

                transform: translateY(-10px);
            }

            img {
                width: 72px;
                height: 72px;
                margin-bottom: 1em;

            }
        }
    }
}

.club-section {
    position: relative;
    background-color: $primary-blue;
    padding: 3em 0 3em 0;
    z-index: 11;


    h2 {
        position: relative;

        color: #fff;
        z-index: 10;

    }

    .cluba-btn {
        margin: 0 auto;
        margin-top: 2em;

    }

    .shape-club {
        background-color: $primary-blue;
        z-index: -1;
    }
}



.news-section {


    background-color: #fff;
    padding: 3em 0 1em 0;
    z-index: 10;
    position: relative;

    .news-container {
        margin-top: 2em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .news-btn {
        margin: 0 auto;
        margin-top: 2em;
    }
}


.gallery-section {
    background-color: $light-grey;
    padding-top: 6em;
    padding-bottom: 6em;




    .gallery-container {
        position: relative;
        margin-top: 3em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 1;

        .gallery-item {
            flex: 1 0 20%;
            width: 180px;
            height: 200px;

            @include respond-max(md) {
                flex: 1 0 30%;
            }

            @include respond-max(sm) {
                flex: 1 0 50%;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center center;
                object-fit: cover;
                transition: 0.5s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .gallery-btn {
        margin: 0 auto;
        margin-top: 2em;
    }
}

.sponsors {
    background-color: $primary-red;
    position: relative;
    padding-top: 0em;
    padding-bottom: 3em;


    .top-shape {
        position: absolute;
        top: -3em;
        content: '';
        background-color: $primary-red;
        width: 100%;
        height: 9em;
        clip-path: ellipse(70% 100% at 50% 100%);
    }

    h2 {
        position: relative;
        color: #fff;
    }



    .sponsors-container {
        margin-top: 3em;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: column;


        .sponsors-group {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: row;

            .sponsors-group-item {

                margin: 0 1.5em;
            }
        }
    }

}

.institutions-section {
    background-color: #fff;


    .iwd-container {
        margin-top: 3em;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2em;


        h4 {
            color: $grey;
            margin-top: 1em;

        }


        img {
            width: auto;
            height: 130px;

            @include respond-max(xsm) {
                height: 100px;


            }

            @include respond-max(xs) {
                height: 70px;


            }
        }
    }
}

.partners-section {
    background-color: $light-grey;
    overflow: hidden;

    .partners-slider {
        margin-top: 3em;
        // border: red 1px solid;
        //         display: flex;
        //   display: grid;
        height: 100%;
        width: 100%;
        // padding: 1em;

        div {
            // border: solid green 1px;

            width: auto;
            height: 100px;
            // margin: 0 1em;
            padding: 0 0.1em;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;

            }

            @include respond-max(md) {

                height: 90px;

            }

            @include respond-max(sm) {

                height: 80px;

            }
        }
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";


.news-single-section-p {
    padding-top: 3em;
    display: block;
    background-color: #fff;
    text-align: center;
    position: relative;

    a,
    a:hover,
    a:active,
    a:visited,
    a:active {
        font-size: 1em;
        color: $primary-blue;


        &:hover {
            color: $primary-red;
        }
    }



    p {
        text-align: justify;
        margin-bottom: 2em;

        @include respond-max (sm) {

            text-align: left;
        }
    }

    h3 {
        text-align: left;
        margin: 1em 0;
    }

    h4 {
        text-align: left;
        color: $primary-red;
        margin: 1em 0;

    }



    ul {
        color: $grey;
        list-style: disc;

        padding: 0;

        li {
            margin-left: 2em;
            padding: 0;
        }
    }

    ol {
        color: $grey;
        margin: 2em 0;
        padding: 0;

        li {
            margin-left: 2em;
            padding: 0;
        }


    }

    .news-single-imgcover {
        margin: 3em auto;
        width: 100%;
        max-height: 550px;
        object-fit: cover;
        object-position: center center;
        box-shadow: 2px 2px 4px 0 #c4c4c4;
        border-radius: 7px;


        @include respond-max (md) {
            // width: 100%;
            max-height: 500px;

        }
    }


    .news-single-gallery {
        margin-top: 3em;
        // justify-content: center;
        // align-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        img {
            cursor: pointer;
            margin: 1em;
            object-fit: cover;
            width: 300px;

            height: 180px;
            transition: 0.5s;
            box-shadow: 2px 2px 4px 0 #c4c4c4;
            border-radius: 7px;

            &:hover {
                transform: scale(1.1);
            }

            @include respond-max (xs) {

                max-width: 250px;

                max-height: 180px;
            }
        }
    }

    .news-single-autor-data {
        margin-top: 3em;
    }

    span {
        color: $grey;
        display: block;
        margin-top: 0em;
        text-align: left;
    }

    p {}

    .srlwrapper {
        position: relative;
        display: block;
        z-index: 10000;
    }

    .btn-news-singel {
        margin: 0 auto;
        margin-top: 2em;
    }
}
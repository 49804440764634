@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.header-pages {

    position: relative;
    width: 100vw;
    height: 400px;
    object-fit: cover;

    overflow: hidden;

    @include respond-max(md) {
        height: 250px;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.3;
        width: 100%;
        height: 400px;
        z-index: 10;

        @include respond-max(md) {
            height: 250px;
        }
    }

    .header-pages-img {
        position: relative;
        width: 100vw;
        height: 400px;

        object-fit: cover;
        object-position: center center;

        @include respond-max(md) {
            height: 300px;
        }
    }


}
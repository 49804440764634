@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";



.volunteers-section-p {
    flex: 1;

    .volunteers-container {
        margin-top: 3em;
    }

    .volunteers-img {
        height: 400px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 3em;
    }
}
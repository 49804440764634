//colors

//kolory:

// czerwony: e20613
// niebieski: 243f91
// szary: 606060
// ciemnoniebieski w menu rozwijanym: 1b2f6e



$primary-red: #e20613;
$primary-blue: #243f91;
$white: #fff;
$grey: #787878;
$light-grey: #ececec;
$dark-blue: #1b2f6e;
$font-main: 'azo-sans-web',
sans-serif;

$box-shadow-main: 2px 2px 10px 0 #3b2828;
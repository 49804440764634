@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

@import-normalize;

@import "../src/sass/_variables.scss";
@import "../src/sass/_mixins.scss";
@import "../src/sass/_layout.scss";


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

*:after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

}

html {
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Mulish', sans-serif;
  min-height: -webkit-fill-available;
  height: 100%;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  width: 100%;
  scroll-behavior: smooth;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  caret-color: transparent;


}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;

}

.fade-enter {
  opacity: 0.2;
  background-color: #fff;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.coockie-container {
  color: #fff;
  // border: rgba(255, 255, 255, 0.5) solid 1px; 
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 2em 1em;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  flex-direction: row;


  @include respond-max(sm) {
    flex-direction: column;
  }



  .coockie-content {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: $primary-blue;
      font-weight: 600;
      font-size: 1em;
      letter-spacing: 1px;

      line-height: 1.4;
      padding-right: 1em;
      max-width: 800px;
      margin-bottom: 2em;
    }

    a {
      color: $primary-blue;
      font-size: 1em;
      font-weight: 500;


      &:hover {
        color: $primary-red
      }

      .politics-icon {
        font-size: 1.2em;
        margin-right: 0.5em;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
      }

    }


  }

  .btn-decline {
    border: red;

  }

  .btn-coockie {
    margin: 1em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    transition: all 0.3s;
    border: none;
    outline: none;
    width: 100px;
    z-index: 100;
    text-align: center;
    border: solid 2px $primary-blue;

    background-color: $primary-blue;

    padding: 0.8em 1.8em 0.8em 1.8em;
    color: #fff;




    @include respond-max(sm) {}

    &:hover {
      color: $primary-blue;
      background-color: #fff;
      border: solid 2px $primary-blue;

    }

    @include respond-max(sm) {

      font-size: 0.8em;

    }

  }

  .btn-decline {
    background-color: $primary-red;
    border: solid 2px $primary-red;


    &:hover {
      color: $primary-red;


    }

  }




}
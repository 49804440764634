@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

.contact-section {
    background-color: #fff;
    width: 100%;

    .contact-form {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin-top: 3em;

        @include respond-max(md) {

            width: 70%;
        }

        @include respond-max(sl) {

            width: 100%;
        }

        div {
            margin: 0.5em;
            width: 100%;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            input,
            textarea {
                cursor: pointer;
                width: 100%;
                padding: 1em;
                font-size: 1.1em;
                background-color: #fff;
                outline-style: none;
                box-shadow: none;
                border: 1px solid #243f9136;

                &:hover,
                &:focus {
                    outline-style: none;
                    box-shadow: none;
                    border-color: $primary-blue;
                }
            }

            input {
                height: 3em;
            }

            textarea {
                resize: vertical;

                min-height: 100px;
            }

            .contact-btn {
                margin: 0;
            }
        }

        .contact-form-message-back {
            p {
                color: $primary-red;
            }
        }
    }
}

.alert-validation {
    position: relative;

    &::before,
    &::after {
        right: 0.8em;
    }

    &::before {
        content: attr(data-validation);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 0.8em;
        font-size: 0.9em;

        visibility: hidden;
    }

    &::after {
        color: #000;
        cursor: pointer;
        content: "!";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover:before {
        visibility: visible;
    }
}
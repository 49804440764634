@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

;


.gallery-p-section {



    .gallery-container {

        margin-top: 3em;


        .gallery-item {
            margin-top: 2em;

            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: row;
            flex-wrap: wrap;

            h3 {
                display: block;
                width: 100%;
                margin-bottom: 1em;
            }



            .image-card {


                padding: 0.3em;
                margin: 0.3em;


                img {
                    object-fit: cover;
                    max-width: 300px;
                    max-height: 180px;
                    transition: 0.5s;

                    &:hover {
                        transform: scale(1.1);
                    }

                    @include respond-max (xs) {

                        max-width: 250px;

                        max-height: 180px;
                    }
                }
            }
        }
    }

    .srlwrapper {
        position: relative;
        display: block;
        z-index: 10000;
    }
}
@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";



header {
    display: block;
    position: relative;
    width: 100%;
    min-height: 650px;

    height: 100vh;
    background-color: $primary-blue;
    overflow: hidden;
    user-select: none;

    @include respond-max(sm) {
        min-height: 568px;

    }

    .header-background {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 650px;
        top: 0;
        left: 0;

        @include respond-max(sm) {
            min-height: 500px;

        }

        .header-bg-layer {
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;

                background-color: #000;
                opacity: 0.4;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }

        img {
            z-index: 0;
            position: relative;
            width: 100vw;
            height: 100vh;
            min-height: 650px;
            object-fit: cover;
            object-position: center center;

            @include respond-max(sm) {
                min-height: 568px;

            }
        }
    }

    .slogan-container {
        position: absolute;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -15%);

        @include respond-max(sm) {
            top: 50%;
        }

        .slogan {

            padding: 0 10px;
            font-size: 3em;
            line-height: 1.1;
            height: 1.3em;
            color: #fff;
            letter-spacing: 1px;
            font-weight: 600;


            @include respond-max(xxl) {
                font-size: 3.7em;

            }

            @include respond-min(xl) {
                font-size: 3.4em;
            }

            @include respond-max(lg) {
                font-size: 2.7em;
            }

            @include respond-max(md) {
                font-size: 2.6em;
            }





            @include respond-max(sm) {
                font-size: 2.4em;
            }


            @include respond-max(xsm) {
                font-size: 1.9em;
            }


            @include respond-max(xs) {
                font-size: 1.4em;
            }

        }

        .slogan-left {
            width: fit-content;
            background-color: $primary-red;

            @include respond-max(sm) {
                margin-left: 1em;

            }
        }

        .slogan-right {
            width: fit-content;
            margin-left: 1em;
            background-color: $primary-blue;




        }
    }



    h1 {
        text-align: center;
        color: #fff;
        position: absolute;
        top: 72%;
        left: 0;
        right: 0;
        z-index: 1000;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 1px;
        font-size: 3em;
        margin-left: 1em;
        margin-right: 1em;

        @include respond-max(xxl) {
            font-size: 3em;

        }

        @include respond-max(xl) {
            font-size: 2.6em;
            top: 67%;
        }

        @include respond-max(md) {
            top: 67%;
            font-size: 1.9em;

        }

        @include respond-max(sm) {
            top: 65%;

            font-size: 1.6em;
            margin-left: 1.5em;
            margin-right: 1.5em;
        }

        @include respond-max(xs) {

            font-size: 1.3em;
            margin-left: 1em;
            margin-right: 1em;
        }
    }

}
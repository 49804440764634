@import "./src/sass/_layout.scss";
@import "./src/sass/_variables.scss";
@import "./src/sass/_mixins.scss";

//reset

//===========================================
//-------------------------------------------
// [role="button"],
// input[type="submit"],
// input[type="reset"],
// input[type="button"],
// button {
//     -webkit-box-sizing: content-box;
//     -moz-box-sizing: content-box;
//     box-sizing: content-box;
// }

// /* Reset `button` and button-style `input` default styles */
// input[type="submit"],
// input[type="reset"],
// input[type="button"],
// input[type="text"],
// button {
//     border: none;
//     outline: none;
//     background: none;
//     color: inherit;
//     /* cursor: default; */
//     font: inherit;
//     line-height: normal;
//     overflow: visible;
//     padding: 0;
//     -webkit-appearance: button;
//     /* for input */
// }

// input::-moz-focus-inner,
// button::-moz-focus-inner {
//     border: 0;
//     padding: 0;
//     border: none;
//     outline: none;
// }

// /* Make `a` like a button */
// [role="button"] {
//     color: inherit;
//     cursor: default;
//     display: inline-block;
//     text-align: center;
//     text-decoration: none;
//     white-space: pre;
//     border: none;
//     outline: none;
// }

//-------------------------------------------
//===========================================







.search-bar {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;


    label {
        margin: 1em;

    }

    input[type=text],
    input[type=button] {
        -webkit-appearance: none;
        -webkit-border-radius: 0;
    }

    input[type=text] {

        font-size: 1em;
        position: relative;
        padding: 0.5em 1em;
        width: 280px;
        min-width: 180px;
        border: solid 1px $grey;
        outline: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        box-sizing: border-box;
        border-radius: 0px;
        background-repeat: no-repeat;
        background-color: none;
        resize: none;




        &:focus {
            outline: none;
            background-color: transparent;
            border: solid 1px $primary-blue;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
        }

    }

    div {
        top: 50%;
        position: absolute;
        transform: translateY(-40%);
        right: 10px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
            filter: invert(23%) sepia(16%) saturate(5355%) hue-rotate(203deg) brightness(95%) contrast(97%);
            transition: all 0.2s;

        }
    }

    &:hover {
        div {

            img {
                transform: scale(1.1);
            }
        }
    }
}